import http from '@/http-common'

const getAll = async () => {
  return await http
    .get('/immobile')
    .then(r => r.data)
    .catch(error => {
      throw error.response?.data || error.response || error
    })
}
const get = async (id) => {
  return await http
    .get('/immobile/'+id)
    .then(r => r.data)
    .catch(error => {
      throw error.response?.data || error.response || error
    })
}

const create = async data => {
  return await http
    .post('/immobile', data)
    .then(r => r.data)
    .catch(error => {
      throw error.response?.data || error.response || error
    })
}
const update = async (id, data) => {
  return await http
    .put(`/immobile/${id}`, data)
    .then(r => r.data)
    .catch(error => {
      throw error.response?.data || error.response || error
    })
}

const search = async query => {
  return await http
    .get('/immobile/search', {
      params: {
        s: query,
      },
    })
    .then(r => r.data)
    .catch(error => {
      throw error.response?.data || error.response || error
    })
}

const attachmentUpload = async (immobileId, data) => {
  var formData = new FormData()
  formData.append('file', data.file)
  formData.append('name', data.name)
  return http
    .post(`/immobile/${immobileId}/attachment`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(r => r.data)
    .catch(error => {
      throw error.response?.data || error.response || error
    })
}

const attachmentDestroy = async (immobileId, attachmentId) => {
  return await http
    .delete(`/immobile/${immobileId}/attachment/${attachmentId}`)
    .then(r => r.data)
    .catch(error => {
      throw error.response?.data || error.response || error
    })
}

export default {
  get,
  getAll,
  create,
  update,
  search,
  attachment: {
    upload: attachmentUpload,
    destroy: attachmentDestroy,
  },
}
