<template>
  <v-dialog v-model="dialog" max-width="auto" scrollable fullscreen hide-overlay>
    <v-card>
      <v-card-title>
        <span class="text-h5">{{ immobile.id ? 'Editar Imóvel' : 'Novo Imóvel' }}</span>
      </v-card-title>

      <v-card-text>
        <v-container fluid>
          <v-form v-model="valid" @submit.prevent="">
            <v-row>
              <v-col cols="12">
                <v-radio-group v-model="immobilePropriety" :mandatory="true" row hide-details class="pt-0 mt-0">
                  <v-radio label="Próprio" :value="true"></v-radio>
                  <v-radio label="Terceiro" :value="false"></v-radio>
                </v-radio-group>
              </v-col>

              <v-col cols="12" md="3" v-if="!immobilePropriety">
                <client-selector v-model="immobile.owner" outlined dense label="Responsável" />
              </v-col>

              <v-col cols="4" md="1">
                <v-text-field
                  v-model="immobile.block"
                  type="tel"
                  label="Quadra"
                  min="0"
                  outlined
                  dense
                  hide-details
                  :rules="[v => !!v || 'Campo obrigatório']"
                ></v-text-field>
              </v-col>

              <v-col cols="4" md="1">
                <v-text-field
                  v-model="immobile.lot"
                  type="tel"
                  label="Lote"
                  min="0"
                  outlined
                  dense
                  hide-details
                  :rules="[v => !!v || 'Campo obrigatório']"
                ></v-text-field>
              </v-col>

              <v-col cols="4" md="1">
                <v-text-field
                  v-model="immobile.area"
                  label="Área m2"
                  type="number"
                  min="0"
                  step="0.01"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>

              <v-col cols="6" md="2">
                <v-text-field
                  v-model="immobile.registry"
                  label="Registro do Imóvel"
                  outlined
                  dense
                  hide-details
                  :rules="[v => !!v || 'Campo obrigatório', v => v > 0 || 'Valor deve ser maior que 0']"
                ></v-text-field>
              </v-col>

              <v-col cols="6" md="2">
                <v-text-field
                  v-model="immobile.iptuRegistry"
                  label="IPTU"
                  outlined
                  dense
                  hide-details
                  :rules="[v => !!v || 'Campo obrigatório', v => v > 0 || 'Valor deve ser maior que 0']"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="2">
                <v-combobox
                  v-model="immobile.type"
                  label="Tipo"
                  :items="propertyType"
                  outlined
                  dense
                  hide-details
                  :rules="[v => !!v || 'Campo obrigatório']"
                >
                  >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>
                          Nenhum resultado com este nome. Aperte <b>Tab</b> para adicionar um novo.
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-combobox>
              </v-col>

              <v-col cols="12" md="9">
                <address-search
                  v-model="immobile.address"
                  :defaultValue="defaultAddress"
                  :autoRun="true"
                ></address-search>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="immobile.complement"
                  label="Complemento"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>

              <!-- <v-col cols="12" class="pb-0">
              <v-file-input
                v-model="editedItem.photoProperties"
                label="Fotos"
                :rules="sizeImage"
                prepend-icon="mdi-camera"
                outlined
                dense
                multiple
                small-chips
                accept="image/png, image/jpeg, image/bmp"
              ></v-file-input>
            </v-col> -->

              <v-col cols="12" class="pt-0">
                <v-textarea
                  v-model="immobile.description"
                  label="Descrição"
                  outlined
                  dense
                  hide-details
                  :rules="[v => !!v || 'Campo obrigatório']"
                ></v-textarea>
              </v-col>

              <v-col cols="12">
                <label>Status:</label>
                <v-radio-group v-model="immobile.active" :mandatory="true" row hide-details class="pt-0">
                  <v-radio label="Ativo" :value="true"></v-radio>
                  <v-radio label="Inativo" :value="false"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions class="px-8 pb-8">
        <v-spacer></v-spacer>
        <v-btn color="primary" :disabled="loading" class="mr-2 mt-2" outlined @click="close">Cancelar</v-btn>
        <v-btn color="primary" :disabled="!valid" :loading="loading" class="mt-2" @click="save">Salvar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import AddressSearch from '../global/AddressSearch.vue'
import IMMOBILE from '@/services/immobile'
import ClientSelector from '../global/ClientSelector.vue'

const defaultData = () => ({
  address: null,
  active: true,
  type: null,
  block: '',
  lot: '',
  area: '',
  registry: '',
  iptuRegistry: '',
  complement: '',
  description: '',
})
export default {
  components: { AddressSearch, ClientSelector },
  data() {
    return {
      loading: false,
      dialog: false,
      valid: false,
      immobilePropriety: 1,
      immobile: defaultData(),
      propertyType: ['Apartamento', 'Casa', { text: 'Sala Comercial', value: 'SalaComercial' }],
      defaultAddress: null,
    }
  },
  methods: {
    async save() {
      try {
        this.loading = true
        if (!this.immobile.id) {
          if (!this.immobile.address) throw new Error('Endereço não informado')
          await IMMOBILE.create(this.immobile)
        } else {
          await IMMOBILE.update(this.immobile.id, this.immobile)
        }
        this.$emit('success')
        this.close()
      } catch (error) {
        console.log(error)
        this.$toast.fire({
          icon: 'error',
          title: error.message || 'Erro ao salvar imóvel',
        })
        this.$emit('error', error)
      } finally {
        this.loading = false
      }
    },
    open(data) {
      this.dialog = true
      this.immobile = Object.assign(defaultData(), data)
      if (data) {
        this.immobilePropriety = !data.ownerId
        this.immobile.owner = data.ownerId || null
        this.defaultAddress = `${data.Address.street}, ${data.Address.number} - ${data.Address.zipCode} - ${data.Address.city} - ${data.Address.state}`
      }
    },
    close() {
      this.loading = false
      this.dialog = false
      this.immobile = defaultData()
    },
  },
  created() {
    this.$root.$on(this.componentName, this.open)
  },
  props: {
    componentName: {
      type: String,
      default: 'immobile-modal',
    },
  },
}
</script>

<style></style>
